import { AppModule } from 'app/module';
import { JobFieldsListingItem } from 'module/jobFieldsListingItem/main';

import Template from './template.hbs';
import './styles.scss';

export class JobFieldsListing extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        this.dom.showMoreButton = this.dom.el.querySelector('.m-jobFieldsListing__showMoreButton');
        this.dom.jobFieldsList = this.dom.el.querySelector('.m-jobFieldsListing__list');
        this.dom.jobFieldsListingItems = this.dom.el.querySelectorAll(
            '.m-jobFieldsListing__listItem .m-jobFieldsListingItem',
        );

        if (this.dom.jobFieldsListingItems) {
            this.jobFieldsListingItem = [];
            for (let i = 0; i < this.dom.jobFieldsListingItems.length; i += 1) {
                this.jobFieldsListingItem[i] = new JobFieldsListingItem(this.dom.jobFieldsListingItems[i]);
            }
        }
    }

    getPropsFromDom() {
        const jobFieldListItemsProps = this.getJobFieldListItemsProps();

        return {
            showMore: this.dom.jobFieldsList.classList.contains('m-jobFieldsListing__list--showMore'),
            jobFieldItems: jobFieldListItemsProps,
        };
    }

    domEvents() {
        this.dom.showMoreButton.addEventListener('click', () => {
            this.updateProps({ showMore: !this.props.showMore }, true);
        });
    }

    getJobFieldListItemsProps() {
        const jobFieldListItemsProps = {};
        if (this.jobFieldsListingItem) {
            for (let i = 0; i < this.dom.jobFieldsListingItems.length; i += 1) {
                jobFieldListItemsProps[i] = this.jobFieldsListingItem[i].props;
            }
        }
        return jobFieldListItemsProps;
    }
}
