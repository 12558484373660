// Internal API endpoints
export const API_ENDPOINT_DISLIKE_CHECK_MODAL = '/internal/api/dislike/job/check-modal';
export const API_ENDPOINT_DISLIKE_JOB_ADD = '/internal/api/dislike/job/add';
export const API_ENDPOINT_DISLIKE_JOB_REMOVE = '/internal/api/dislike/job/remove';
export const API_ENDPOINT_DISLIKE_COMPANY_ADD = '/internal/api/dislike/company/add';
export const API_ENDPOINT_DISLIKE_COMPANY_REMOVE = '/internal/api/dislike/company/remove';
export const API_ENDPOINT_DISLIKED_COMPANY_NAMES = '/internal/api/dislike/company/data';

export const API_ENDPOINT_WATCHLIST_ENTRIES = '/internal/api/watchlist/get-ids';
export const API_ENDPOINT_WATCHLIST_CREATE_OR_UPDATE = '/internal/api/watchlist/create-or-update';
export const API_ENDPOINT_WATCHLIST_REMOVE = '/internal/api/watchlist/remove';
export const API_ENDPOINT_WATCHLIST_MEMO_UPDATE = '/internal/api/watchlist/memo/update';

export const API_ENDPOINT_COMPANYFOLLOW_IDS = '/internal/api/cf/get-ids';
export const API_ENDPOINT_COMPANYFOLLOW_ADD = '/internal/api/cf/follow';
export const API_ENDPOINT_COMPANYFOLLOW_REMOVE = '/internal/api/cf/unfollow';

export const API_ENDPOINT_JOBS_BY_IDS = '/internal/api/jobs/get-by-ids';

export const API_ENDPOINT_FILTERS = '/internal/api/jobs/filters';

export const API_ENDPOINT_HEARTBEAT = '/internal/api/heartbeat';

export const API_ENDPOINT_LOG_APPLICATION_CLICK = '/internal/api/apply/log-application-click';

export const API_ENDPOINT_JOBALARM_DELETE = '/internal/api/jobalarm/delete';
export const API_ENDPOINT_JOBALARM_CREATE = '/internal/api/jobalarm/create';
export const API_ENDPOINT_JOBALARM_MODAL_DATA = '/internal/api/jobalarm/modal';
export const API_ENDPOINT_JOBALARM_NOTIFICATION_INTERVAL = '/internal/api/jobalarm/mail-subscription';

export const API_ENDPOINT_AUTOCOMPLETE_KEYWORDS = '/internal/api/autocomplete/keywords';
export const API_ENDPOINT_AUTOCOMPLETE_COMPANY_KEYWORDS = '/internal/api/autocomplete/company-keywords';
export const API_ENDPOINT_AUTOCOMPLETE_LOCATIONS = '/internal/api/autocomplete/locations';
export const API_ENDPOINT_AUTOCOMPLETE_JOBS = '/internal/api/autocomplete/jobs';
export const API_ENDPOINT_AUTOCOMPLETE_SKILLS = '/internal/api/autocomplete/skills';

export const API_ENDPOINT_PROXIMITY_LOCATION = '/internal/api/proximity/location';

export const API_ENDPOINT_JOB_MATCHING_CREATE_PROFILE = '/internal/api/matching/create-profile';
export const API_ENDPOINT_JOB_MATCHING_UPDATE_CV = '/internal/api/matching/update-cv';

export const API_ENDPOINT_RECRUITER_INQUIRY = '/internal/api/f/company-inquiry';
export const API_ENDPOINT_RECRUITER_INQUIRY_GDPR_APPROVE = '/internal/api/f/company-inquiry-approve-consent';

export const API_ENDPOINT_STATISTICS = '/internal/api/stats';

// Web routes
export const ENDPOINT_USER_LOGIN = '/user/login/oauth';
export const ENDPOINT_USER_REGISTER = '/user/register/oauth';

export const ENDPOINT_COMPANIES = '/a';

export const ENDPOINT_JOBS = '/jobs';

export const ENDPOINT_JOBALARM = '/jobalarm';

export const ENDPOINT_COMPANYALARM = '/companyalarm';

export const ENDPOINT_WATCHLIST = '/watchlist';

export const ENDPOINT_MATCHING = '/matching';

export const ENDPOINT_HOKIFY_JOBS = '/hokify/jobs';

export const ENDPOINT_SETTINGS_NEWSLETTER = '/settings/notifications/newsletter';
