import { AppModule } from 'app/module';
import { app } from 'app/app';
import Template from './template.hbs';
import './styles.scss';

export class Radio extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            input: '.k-c-radio__input',
            label: '.k-c-radio__label',
        };
    }

    getPropsFromDom() {
        return {
            name: this.dom.input.name,
            value: this.dom.input.value,
            disabled: this.dom.input.disabled,
            checked: this.dom.input.checked,
            count: this.dom.input.getAttribute('data-count'),
            label: this.dom.label ? this.dom.label.innerText : null,
            checkedOnInteraction: this.dom.el.getAttribute('data-checked') === 'true',
        };
    }

    domEvents() {
        this.dom.input.addEventListener('click', (e) => this.toggle(e));
    }

    ready() {
        app.events.on('firstUserInteraction', () => this.onFirstUserInteraction());
    }

    onFirstUserInteraction() {
        if (this.props.checkedOnInteraction) {
            this.dom.el.checked = true;
        }
    }

    toggle(e) {
        e.stopPropagation();
        if (!this.props.checked) {
            this.events.emit('toggle');
        }
    }
}
