import { AppModule } from 'app/module';
import './styles.scss';
import { trackGa4 } from 'util/googleAnalytics';
import Template from './template.hbs';

export class SmallFooter extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            groupLogoLink: '.m-smallFooter__groupLogoLink',
        };
    }

    domEvents() {
        if (this.dom.groupLogoLink) {
            this.dom.groupLogoLink.addEventListener('click', () => {
                trackGa4({
                    element: 'FOOT: norika',
                    elementDetail: null,
                });
            });
        }
    }
}
