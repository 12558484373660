import 'app/sentry';
import 'app/actionLogger';
import Dispatcher from 'dispatcherjs';
import { heartbeat } from 'app/heartbeat';
import { enableExternalScripts } from 'util/enableExternalScripts';
import { performanceMark } from 'util/performanceMark';
import { refreshToken } from 'util/auth';
import { hasConsentGrantedOrDenied } from 'util/consent';

class App {
    constructor() {
        this.controller = null;
        this.events = new Dispatcher();
        this.events.setMaxListeners(30);
        this.heartbeat = heartbeat;
    }

    boot(Controller) {
        performanceMark('appBoot');
        // If controller is already set, it has been booted before and we are in a HMR situation
        if (this.controller) {
            const $controllerRoot = document.querySelector('body');
            this.controller = new Controller($controllerRoot);
            this.domEvents();
        }
        document.addEventListener('DOMContentLoaded', () => {
            performanceMark('DOMContentLoaded');
            const $controllerRoot = document.querySelector('body');
            this.controller = new Controller($controllerRoot);
            this.domEvents();
        });

        if (hasConsentGrantedOrDenied()) enableExternalScripts();
    }

    domEvents() {
        this.windowLoadListener = this.onWindowLoaded.bind(this);
        window.addEventListener('load', this.windowLoadListener);

        this.windowResizeListener = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.windowResizeListener);

        this.firstInteractionListener = this.onFirstUserInteraction.bind(this);
        document.body.addEventListener('keydown', this.firstInteractionListener);
        document.body.addEventListener('mousedown', this.firstInteractionListener);
        document.body.addEventListener('mousemove', this.firstInteractionListener);
        document.addEventListener('scroll', this.firstInteractionListener, { passive: true });
        document.body.addEventListener('touchstart', this.firstInteractionListener);

        this.pageActiveListener = this.onPageActive.bind(this);
        document.body.addEventListener('keydown', this.pageActiveListener);
        document.body.addEventListener('mousedown', this.pageActiveListener);
        document.body.addEventListener('mousemove', this.pageActiveListener);
        document.addEventListener('scroll', this.pageActiveListener, { passive: true });
        document.body.addEventListener('touchstart', this.pageActiveListener);
        setTimeout(this.pageActiveListener, 1000);

        document.body.addEventListener('keyup', (e) => this.events.emit('keyup', e));

        this.heartbeat.events.on('error', this.onHeartbeatError);
    }

    onWindowLoaded() {
        this.events.emit('onWindowLoaded');
        window.removeEventListener('load', this.windowLoadListener);
    }

    onWindowResize() {
        this.events.emit('onWindowResize');
    }

    /**
     * first user interaction event should be used on features which only rely
     * on user interaction aka google cheating all other late load events
     * should use the page active event.
     */
    onFirstUserInteraction() {
        if (this.hadFirstUserInteractionEvent) return;
        performanceMark('firstUserInteraction');

        this.hadFirstUserInteractionEvent = true;
        this.events.emit('firstUserInteraction');

        if (!hasConsentGrantedOrDenied()) enableExternalScripts();

        document.body.removeEventListener('keydown', this.firstInteractionListener);
        document.body.removeEventListener('mousedown', this.firstInteractionListener);
        document.body.removeEventListener('mousemove', this.firstInteractionListener);
        document.removeEventListener('scroll', this.firstInteractionListener, { passive: true });
        document.body.removeEventListener('touchstart', this.firstInteractionListener);
    }

    /**
     * the same as first userinteraction except it is also triggert by timeout
     */
    onPageActive() {
        if (this.hadPageActiveEvent) return;

        performanceMark('pageActive');

        this.hadPageActiveEvent = true;
        this.events.emit('pageActive');

        document.body.removeEventListener('keydown', this.pageActiveListener);
        document.body.removeEventListener('mousedown', this.pageActiveListener);
        document.body.removeEventListener('mousemove', this.pageActiveListener);
        document.removeEventListener('scroll', this.pageActiveListener, { passive: true });
        document.body.removeEventListener('touchstart', this.pageActiveListener);
    }

    /**
     * Check for errors in heartbeat response
     *
     * If the session expired, we have to obtain a new access_token by initiating the login-flow.
     *
     * @param error
     */
    onHeartbeatError(e) {
        if (e.arguments[0].error.status === 401) {
            refreshToken();
        }
    }
}

export const app = new App();
window.app = app;
