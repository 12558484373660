import { AppModule } from 'app/module';
import { loadYoutubeApi } from 'util/youtubeApi';
import { CONSENT_GROUPS, hasConsent, onConsentChange, openConsentSettings } from 'util/consent';
import Template from './template.hbs';
import './styles.scss';

export class Video extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            consentLink: '.m-video__consentLink',
            videoPlayer: '.m-video__player',
        };
    }

    getPropsFromDom() {
        return {
            videoId: this.dom.el.getAttribute('data-yt-id'),
            playCategory: this.dom.el.getAttribute('data-play-category'),
            playEvent: this.dom.el.getAttribute('data-play-event'),
            lateLoad: this.dom.el.getAttribute('data-lateload'),
            insideLightBox: this.dom.el.classList.contains('.m-video--insideLightBox'),
        };
    }

    ready() {
        if (this.props.lateLoad) {
            this.addViewportObserver();
        } else {
            this.playerInit();
        }
    }

    domEvents() {
        if (this.dom.consentLink) {
            this.dom.consentLink.addEventListener('click', () => {
                openConsentSettings();
            });
        }
    }

    playerInit() {
        let isInitialized = false;
        const init = async () => {
            if (isInitialized || !this.hasConsent()) {
                return;
            }

            isInitialized = true;
            await loadYoutubeApi();

            this.isStarted = false;
            this.videoPlayer = new window.YT.Player(this.dom.videoPlayer, {
                height: '390',
                width: '640',
                videoId: this.props.videoId,
                host: 'https://www.youtube-nocookie.com',
                playerVars: {
                    rel: 0,
                    autoload: 1,
                    iv_load_policy: 3,
                    showinfo: 0,
                    frameborder: 0,
                },
                events: {
                    onReady: (event) => this.onPlayerReady(event),
                    onStateChange: (event) => this.stateChanged(event),
                },
            });
        };

        onConsentChange(init);
        init();
    }

    onPlayerReady(event) {
        this.events.emit('onReady', event);
    }

    stateChanged(event) {
        if (!this.isStarted && event.data === window.YT.PlayerState.PLAYING) {
            this.events.emit('play');
            this.isStarted = true;
        }

        if (this.isStarted && event.data === window.YT.PlayerState.ENDED) {
            this.videoPlayer?.stopVideo();
            this.isStarted = false;
        }
    }

    entersViewport() {
        if (this.props.lateLoad) {
            this.playerInit();
        }
    }

    pauseVideo() {
        this.videoPlayer?.pauseVideo?.();
    }

    hasConsent() {
        return hasConsent(CONSENT_GROUPS.performance);
    }
}
