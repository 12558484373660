import { AppModule } from 'app/module';
import { ProposalItemPill } from 'module/proposalItemPill/main';
import Template from './template.hbs';
import './styles.scss';

export class ProposalPillList extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            proposalItems: ['.m-proposalItemPill'],
        };
    }

    subs() {
        this.proposalItems = [];
        this.dom.proposalItems.forEach((domItem) => {
            this.proposalItems.push(new ProposalItemPill(domItem));
        });
    }

    subEvents() {
        this.proposalItems.forEach((item) => this.propagateEvents(item, 'proposalLinkClicked'));
    }
}
