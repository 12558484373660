import { AppModule } from 'app/module';
import { getJson } from 'util/xhr';
import { params as urlParams } from 'util/url';
import { touch } from 'util/detect';
import { trackGa4 } from 'util/googleAnalytics';
import {
    KEYCODE_DOWN,
    KEYCODE_DOWN_EDGE,
    KEYCODE_ENTER,
    KEYCODE_ESCAPE,
    KEYCODE_ESCAPE_EDGE,
    KEYCODE_UP,
    KEYCODE_UP_EDGE,
} from 'app/keyCodes';
import { escapeRegex } from 'util/regex';
import Template from './template.hbs';
import './styles.scss';

const maxCacheItemsToDisplay = 3;
const maxItemsToDisplay = 5;

export class Autocomplete extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    ready() {
        this.updateProps({ cacheItems: [] });
    }

    set({ endpoint, cache, input, removeFromCache }) {
        if (endpoint) this.endpoint = endpoint;
        if (removeFromCache) this.removeFromCache = removeFromCache;

        if (input) {
            this.input = input;
            this.inputEvents();
        }

        if (cache) {
            this.cacheFunction = cache;
            this.loadHistoryItems();
        }
    }

    async loadHistoryItems() {
        if (!this.cacheFunction) return;

        let cacheItems = (await this.cacheFunction()) ?? [];
        cacheItems = cacheItems
            .map((item) => (
                {
                    label: item,
                    icon: { isActive: true, clock: true },
                    isHistoryItem: true,
                }));
        this.updateProps({ cacheItems });
    }

    domBindings() {
        return {
            items: ['.m-autocomplete__item'],
            removeTriggers: ['.m-autocomplete__removeTrigger'],
        };
    }

    domEvents() {
        const isTouchDevice = touch();

        for (let i = 0; i < this.dom.items.length; i += 1) {
            if (!isTouchDevice) {
                this.dom.items[i].addEventListener('mouseover', () => this.selectItemByIndex(i + 1));
            }
            this.dom.items[i].addEventListener('mousedown', () => this.insertItemByIndex(i + 1));
        }

        this.dom.removeTriggers.forEach((trigger, index) => {
            trigger.addEventListener('mousedown', async (e) => {
                e.stopPropagation();
                await this.removeFromCache(this.props.items[index].label);
                await this.loadHistoryItems();
                const emptyQuery = this.input.getValue().trim().length === 0;
                let items = [];
                if (!emptyQuery && this.props.networkItems) items = this.props.networkItems;
                this.renderItems({ items });
                setTimeout(() => this.input.setFocus(), 10); // refocus input after re-rendering is done
            });
        });
    }

    getPropsFromDom() {
        return {
            type: this.dom.el.getAttribute('data-type'),
            selectedIndex: false,
            hasItems: this.dom.items.length > 0,
            id: this.dom.el.getAttribute('id'),
            isKeywords: this.dom.el.getAttribute('data-iskeywords'),
            isLocations: this.dom.el.getAttribute('data-islocations'),
            eventCategory: document.documentElement.getAttribute('data-event-category'),
        };
    }

    bindInput(input) {
        this.input = input;
        this.inputEvents();
    }

    inputEvents() {
        this.input.events.on('click', () => {
            if (this.input.getValue() === '') this.displayCacheEntries();
        });
        this.input.events.on('focus', () => {
            if (this.input.getValue() === '') this.displayCacheEntries();
        });
        this.input.events.on('keyup', (event) => {
            this.inputKeydown(event.arguments[0]);
        });
        this.input.events.on('blur', () => {
            setTimeout(() => this.close(), 250);
        });
    }

    inputKeydown(keyCode) {
        if (keyCode === KEYCODE_DOWN || keyCode === KEYCODE_DOWN_EDGE) {
            this.selectNextItem();
        } else if (keyCode === KEYCODE_UP || keyCode === KEYCODE_UP_EDGE) {
            this.selectPreviousItem();
        } else if (keyCode === KEYCODE_ENTER && this.props.selectedIndex) {
            this.insertSelectedItem();
        } else if (keyCode === KEYCODE_ENTER && !this.props.selectedIndex) {
            this.hide();
        } else if (keyCode === KEYCODE_ESCAPE || keyCode === KEYCODE_ESCAPE_EDGE) {
            this.hide();
        } else if (keyCode !== KEYCODE_ENTER) {
            this.handleInputQuery();
        }
    }

    selectItemByIndex(index) {
        const updatedItems = this.props.items;

        // remove old selected
        for (let i = 0; i < updatedItems.length; i += 1) {
            updatedItems[i].selected = i === index - 1;
        }

        this.updateProps({
            items: updatedItems,
            selectedIndex: index,
            screenreaderText: updatedItems[index - 1].label,
        }, true);

        this.input?.hasActiveDescendant(true);
    }

    handleInputQuery() {
        // clear jobId for benchmarkJobs
        this.events.emit('clearJobId');

        const query = this.input.getValue().trim();

        // don't fetch suggestions if the user enters a whitespace
        if (this.props.query === query) {
            return;
        }

        this.updateProps({ query });

        if (this.autocompleteRequest) {
            this.autocompleteRequest.abort();
        }

        if (query.length > 0) {
            this.fetch();
            this.events.emit('changed', [query]);
        } else {
            this.events.emit('hide');
            this.hide();
            this.displayCacheEntries();
        }
    }

    fetch() {
        this.autocompleteRequest = getJson(
            urlParams(this.endpoint, { query: this.props.query }),
            {},
            (response) => this.renderItems(response),
            (error) => this.onError(error),
        );
    }

    hide() {
        this.updateProps({
            items: false,
            selectedIndex: false,
            noJobResult: false,
            screenreaderText: null,
        }, true);
        this.input.setAutocompleteExpanded(false);
    }

    close() {
        if (this.input.dom.input === document.activeElement) {
            return;
        }

        if (this.autocompleteRequest) {
            this.autocompleteRequest.abort();
        }

        this.updateProps({
            items: false,
            selectedIndex: false,
            query: undefined,
            noJobResult: false,
            screenreaderText: null,
        }, true);
        this.input.setAutocompleteExpanded(false);
    }

    displayCacheEntries() {
        const items = this.props.cacheItems.slice(0, maxCacheItemsToDisplay);
        this.updateProps({
            items,
            screenreaderText: items.length > 0 ? this.resultCountScreenReaderText(items.length) : '',
            selectedIndex: false,
        }, true);

        if (items.length) {
            this.input.setAutocompleteExpanded(true);
        }
    }

    renderItems(response) {
        let { items } = response;
        const query = this.props.query ?? '';
        let historyItems = this.props.cacheItems ?? [];

        this.updateProps({ networkItems: [...items] });

        // Inject icons if cache results are enabled
        if (historyItems.length > 0) {
            items.forEach((item) => { item.icon.isActive = true; });
        }

        // Prepare cache entries
        historyItems = historyItems
            .filter((item) => item.label.toLowerCase().startsWith(query.toLowerCase()))
            .slice(0, maxCacheItemsToDisplay);

        // Slice response items that are in cache already
        const cacheLabels = historyItems.reduce((acc, cur) => {
            acc.push(cur.label);
            return acc;
        }, []);
        items = items.filter((item) => !cacheLabels.includes(item.label));

        // Inject cache entries, slice to max display amount
        items.unshift(...historyItems);
        items = items.slice(0, maxItemsToDisplay);

        // make query part regular weight
        items = items.map((item) => {
            const index = item.label.toLowerCase().indexOf(query.toLowerCase());
            const replaceStr = item.label.substr(index, query.length);
            const regex = new RegExp(escapeRegex(query), 'i'); // i = case insensitive
            const renderedLabel = item.label
                .replace(regex, `<span class="m-autocomplete__item--regular">${replaceStr}</span>`);
            return {
                ...item,
                renderedLabel,
            };
        });

        this.updateProps({
            items,
            screenreaderText: items.length > 0 ? this.resultCountScreenReaderText(items.length) : '',
            selectedIndex: false,
            noJobResult: this.props.type === 'jobs',
        }, true);

        if (items.length > 0) this.input.setAutocompleteExpanded(true);
    }

    onError() {
        // This triggers on abort, so we ignore all errors
    }

    selectNextItem() {
        if (this.props.items?.length) {
            let newIndex = this.props.selectedIndex + 1;

            // first is 0 #hach
            if (this.props.selectedIndex === false) {
                newIndex = 1;
            }

            // if last select first
            if (newIndex > this.props.items.length
                || this.props.selectedIndex === false) {
                newIndex = 1;
            }
            this.selectItemByIndex(newIndex);
        }
    }

    selectPreviousItem() {
        if (this.props.items?.length) {
            let newIndex = this.props.selectedIndex - 1;
            // if first select last
            if (newIndex < 1) {
                newIndex = this.props.items.length;
            }
            this.selectItemByIndex(newIndex);
        }
    }

    insertItemByIndex(index) {
        const itemIndex = index - 1;
        let locationId = null;

        if (itemIndex < 0) return;

        const item = this.dom.items[index - 1];
        if (!item) return;

        const value = item.innerText;
        this.input.setValue(value.trim());

        if (item.hasAttribute('data-jobid')) {
            this.events.emit('setJobId', item.getAttribute('data-jobid'));
        }

        const elementDetail = this.props.id === 'keywordsAutocomplete' ? 'Keywords' : 'Locations';

        if (this.props.eventCategory === 'page: index') {
            trackGa4({
                event: 'click_element',
                element: 'HOME: Autocomplete',
                element_detail: elementDetail,
            });
        } else if (this.props.eventCategory === 'page: jobs') {
            trackGa4({
                event: 'click_element',
                element: 'JOBS: Autocomplete',
                element_detail: elementDetail,
            });
        } else if (this.props.eventCategory === 'page: companies') {
            trackGa4({
                event: 'click_element',
                element: 'CS: Autocomplete',
                element_detail: elementDetail,
            });
        }

        if (item.hasAttribute('data-locationid')) {
            locationId = item.getAttribute('data-locationid');
        }

        this.input.setFocus(); // refocus input as click blurs
        this.events.emit('changed', [value]);
        this.events.emit('autoCompleteItemClicked', locationId);

        this.hide();
    }

    insertSelectedItem() {
        if (this.props.selectedIndex && this.props.selectedIndex !== false) {
            this.insertItemByIndex(this.props.selectedIndex);
        }
    }

    // Currently only works for jobs due to reliance on "jobId" property sent by BE
    findIdForQuery(query) {
        this.autocompleteRequest = getJson(
            urlParams(this.endpoint, { query }),
            {},
            ({ items }) => {
                const item = items.find(({ label }) => label.toUpperCase() === query.toUpperCase());
                if (item) this.events.emit('setId', item.jobId, true);
            },
        );
    }

    hasItems() {
        return this.getPropsFromDom().hasItems;
    }

    resultCountScreenReaderText(resultCount) {
        const { placeholder } = this.input.props;
        return `${resultCount} Ergebnisse zur Autovervollständigung
        ${placeholder ? `von ${placeholder}` : ''} verfügbar. Navigiere mit den Pfeiltasten.`;
    }
}
