import { AppModule } from 'app/module';
import Splide from '@splidejs/splide';
import { splideI18n } from 'module/slider/splide/splide';
import Template from './template.hbs';
import './styles.scss';

export class Slider extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    ready() {
        this.setup();
    }

    domBindings() {
        this.dom.frame = this.dom.el.querySelector('.m-slider__frame');
        this.dom.dotContainer = this.dom.el.querySelector('.m-slider__dots');
        this.dom.dotListItem = document.createElement('li');
        this.dom.controlNext = this.dom.el.querySelector('.m-slider__next');
        this.dom.controlPrev = this.dom.el.querySelector('.m-slider__prev');
        this.dom.slider = this.dom.el.querySelector('.m-slider__slides');
    }

    getPropsFromDom() {
        return {
            active: !this.dom.el.classList.contains('m-slider--inactive'),
            arrows: !!this.dom.el.classList.contains('m-slider--arrows'),
            arrowPath: this.dom.el.getAttribute('data-arrowPath') ?? undefined,
            dots: !!this.dom.el.classList.contains('m-slider--dots'),
            focus: this.dom.el.getAttribute('data-focus') ?? undefined,
            freeDrag: !!this.dom.el.classList.contains('m-slider--freeDrag'),
            infinity: this.dom.frame ? this.dom.frame.classList.contains('m-slider__frame--infinity') : false,
            perMove: this.dom.el.getAttribute('data-perMove') ?? 1,
            rewind: this.dom.frame ? this.dom.frame.classList.contains('m-slider__frame--rewind') : false,
        };
    }

    setup() {
        this.destroy();
        if (this.dom.el == null) return;

        this.dom.el.classList.remove('m-slider--inactive');

        this.sliderInstance = new Splide(this.dom.el, {
            type: this.props.infinity ? 'loop' : 'slide',
            arrows: this.props.arrows,
            arrowPath: this.props.arrowPath ?? null,
            autoWidth: true,
            rewind: this.props.rewind,
            pagination: this.props.dots,
            drag: this.props.freeDrag ? 'free' : true,
            perMove: this.props.perMove,
            perPage: this.props.perPage ?? 1,
            flickPower: 200,
            focus: this.props.focus,
            i18n: splideI18n,
        }).mount();
    }

    destroy() {
        if (!this.sliderInstance) return;

        this.sliderInstance.destroy();
        this.dom.el.classList.add('m-slider--inactive');
    }

    domElementsExist() {
        if (!this.sliderInstance) return undefined;
        return document.contains(this.sliderInstance.root);
    }

    // Implementation depends on specific slider used (current: Splide)
    go(pattern) {
        this.sliderInstance.go(pattern);
    }

    // Implementation depends on specific slider used (current: Splide)
    getSlideCount() {
        return this.sliderInstance.length;
    }

    // Implementation depends on specific slider used (current: Splide)
    getSlidesDOMNodes() {
        return this.sliderInstance?.Components.Elements.slides;
    }

    // Implementation depends on specific slider used (current: Splide)
    on(eventName, callback) {
        this.sliderInstance?.on(eventName, callback);
    }
}
