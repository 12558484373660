import { AppModule } from 'app/module';
import Template from './template.hbs';
import './styles.scss';

export class HeaderSmall extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            back: '.m-headerSmall__back',
        };
    }
}
