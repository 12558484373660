import { AppModule } from 'app/module';

import './styles.scss';
import Template from './template.hbs';

export class ValidationMessageBase extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            errors: ['li'],
        };
    }

    getPropsFromDom() {
        const errors = [];

        for (let i = 0; i < this.dom.errors.length; i += 1) {
            const $error = this.dom.errors[i];
            const rule = $error.getAttribute('data-rule');
            const value = $error.getAttribute('data-value') || true;
            const message = $error.innerText;

            errors.push({ [rule]: value, message });
        }

        return { errors };
    }
}
