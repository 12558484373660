import { AppModule } from 'app/module';
import './styles.scss';
import { API_ENDPOINT_JOBS_BY_IDS } from 'app/endpoints';
import { VisitedJobsHistory } from 'util/client-side-storage/visitedJobsHistory';
import { getJson } from 'util/xhr';
import { JobCardMini } from 'module/jobCardMini/main';
import { Slider } from 'module/slider/main';
import { mediaQueries } from 'util/mediaQueries';
import { app } from 'app/app';
import Template from './template.hbs';

const numberOfItemsToLoad = 3;

export class RecentlyViewedJobsSlider extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    async ready() {
        const ids = (await VisitedJobsHistory.getJobIds())
            .splice(0, numberOfItemsToLoad);
        if (!ids || ids.length === 0) return;

        // Initialise skeleton animation while waiting for request to finish
        const items = [];
        ids.forEach(() => items.push({ jobsItem: { loading: true } }));
        this.updateProps({ items, hasSearches: true, isSlider: mediaQueries.isMobile() }, true);

        const queryString = ids.reduce((acc, id) => `${acc}jobIds[]=${id}&`, '?').slice(0, -1);
        getJson(API_ENDPOINT_JOBS_BY_IDS + queryString, {}, this.onResponse.bind(this));
    }

    domBindings() {
        return {
            slider: '.m-slider',
            cards: ['.m-jobCardMini'],
        };
    }

    domEvents() {
        app.events.on('onWindowResize', () => this.updateCardsMinHeight());
        mediaQueries.events.on('changed', () => {
            if (this.props.isSlider && !mediaQueries.isMobile() && this.cards.length > 1) {
                this.destroySlider();
                this.updateProps({ isSlider: false }, true);
            } else if (!this.props.isSlider && mediaQueries.isMobile() && this.cards.length > 1) {
                this.updateProps({ isSlider: true }, true);
            }
        });
    }

    initSlider() {
        if (!this.slider || !this.slider.domElementsExist()) {
            this.slider = new Slider(this.dom.slider);
        }
    }

    destroySlider() {
        if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }
    }

    onResponse(response) {
        if (!response || !Array.isArray(response)) return;
        const items = response
            .map((item) => ({ jobsItem: { ...item.jobsItem, date: item.jobsItem.date.replace('am ', '') } }));
        this.updateProps({ items, hasSearches: items.length > 0, isSlider: mediaQueries.isMobile() }, true);
        this.updateCardsMinHeight();
    }

    subs() {
        this.cards = [];
        this.dom.cards?.forEach((card) => this.cards.push(new JobCardMini(card)));

        if (this.dom.slider && this.cards.length > 1) {
            this.initSlider();
        }
    }

    // Sets the height of all cards to the tallest card on mobile, unsets on tablet + up.
    updateCardsMinHeight() {
        // Use querySelectorAll because of dom elements added by slider
        const cards = document.querySelectorAll('.m-jobCardMini');

        // Unset min-height for calculations + because it's unneeded on tablet+up
        cards.forEach(($e) => { $e.style.minHeight = ''; });

        if (!mediaQueries.isMobile()) return;
        // Get tallest card, apply its height as min-height to all cards
        const height = this.cards.reduce((pre, cur) => Math.max(pre, cur.dom.el.getBoundingClientRect().height), 0);
        cards.forEach(($e) => { $e.style.minHeight = `${height}px`; });
    }
}
