import { AppModule } from 'app/module';
import { directFocusTo, getKeyboardFocusableElements } from 'util/a11y';
import Template from './template.hbs';
import './styles.scss';

export class BackToTopButton extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    ready() {
        window.addEventListener('scroll', () => {
            this.setVisible(document.documentElement.scrollTop > 100);
        });
    }

    domEvents() {
        this.dom.el.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            // a11y - next tab leads to first element on page
            directFocusTo(getKeyboardFocusableElements()[0], false, true);
        });
    }

    setVisible(visible = true) {
        if (visible) {
            this.dom.el.classList.remove('m-backToTopButton--hidden');
        } else {
            this.dom.el.classList.add('m-backToTopButton--hidden');
        }
    }
}
