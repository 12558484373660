import { AppModule } from 'app/module';
import { trackGa4 } from 'util/googleAnalytics';
import './styles.scss';
import Template from './template.hbs';

export class DropdownOption extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    getPropsFromDom() {
        return {
            name: this.dom.el.innerText,
            value: this.dom.el.getAttribute('data-value'),
            selected: this.dom.el.classList.contains('k-c-dropdown__listItem--active'),
            disabled: this.dom.el.classList.contains('m-dropdownOption--disabled'),
            isProximity: this.dom.el.getAttribute('data-isproximity') === 'true',
        };
    }

    domEvents() {
        this.dom.el.addEventListener('click', (e) => {
            if (e) e.preventDefault();
            this.setValue();

            if (this.props.isProximity) {
                const eventCategory = document.documentElement.getAttribute('data-event-category');
                let prefix = '';

                if (eventCategory === 'page: index') {
                    prefix = 'HOME';
                } else if (eventCategory === 'page: jobs') {
                    prefix = 'JOBS';
                }

                trackGa4({
                    event: 'click_element',
                    element: `${prefix}: Searchform Radius`,
                    element_detail: this.props.value,
                });
            }
        });
    }

    getValue() {
        return this.props.value;
    }

    setValue() {
        this.events.emit('setValue', this.props);
    }
}
