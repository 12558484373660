import { AppModule } from 'app/module';
import { user } from 'app/user';
import { mediaQueries } from 'util/mediaQueries';
import Template from './template.hbs';
import './styles.scss';

export class LoginModal extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            scene: '.m-loginModal__scene',
            container: '.m-loginModal__container',
            contentInner: '.m-loginModal__contentInner',
            box: '.m-loginModal__box',
            iFrameContainer: '.m-loginModal__iFrameContainer',
            iFrame: '.m-loginModal__iFrame',
            closeTrigger: '.m-loginModal__close',
            overlay: '.m-loginModal__overlay',
        };
    }

    getPropsFromDom() {
        return {
            visible: !!this.dom.overlay,
        };
    }

    domEvents() {
        if (this.dom.closeTrigger) {
            this.dom.closeTrigger.addEventListener('click', () => this.toggle());
        }

        if (this.dom.overlay) {
            this.dom.overlay.addEventListener('click', () => this.toggle());
        }
    }

    receiveMessageFromSSO(event) {
        // The SSO GDPR modal has a fixed-positioned layout, so we can't get valid height for it.
        if (event.data.type === 'event'
            && event.data.action === 'updateHeight'
            && !this.gdprModalIsOpen()
            && this.dom.iFrame) {
            this.dom.iFrameContainer.style.height = `${event.data.value}px`;
        }

        if (event.data.type === 'event' && event.data.action === 'openGdprModal') {
            if (this.dom.container) this.dom.container.style.width = '100%';
            this.dom.scene?.setAttribute('style', 'display: none;');
            this.dom.contentInner?.classList.add('m-loginModal__contentInner--gdpr');
            this.dom.box?.classList.add('m-loginModal__box--gdpr');
            this.dom.iFrameContainer?.classList.add('m-loginModal__iFrameContainer--gdpr');

            if (mediaQueries.isMobile() && this.dom.contentInner) {
                this.dom.contentInner.scrollTop = 0;
            } else if (this.dom.container) {
                this.dom.container.scrollTop = 0;
            }

            this.dom.iFrame?.contentWindow.postMessage({
                type: 'event',
                action: 'gdprChanged',
            }, '*');
        }

        if (event.data.type === 'event' && event.data.action === 'closeGdprModal') {
            if (mediaQueries.isMobile() && this.dom.container) {
                this.dom.container.style.width = '100%';
                this.dom.contentInner.scrollTop = 0;
            } else if (this.dom.container) {
                this.dom.container.style.width = '60%';
                this.dom.container.scrollTop = 0;
            }
            this.dom.scene?.setAttribute('style', 'display: flex;');
            this.dom.contentInner?.classList.remove('m-loginModal__contentInner--gdpr');
            this.dom.box.classList?.remove('m-loginModal__box--gdpr');
            this.dom.iFrameContainer?.classList.remove('m-loginModal__iFrameContainer--gdpr');

            this.dom.iFrame?.contentWindow.postMessage({
                type: 'event',
                action: 'gdprChanged',
            }, '*');
        }

        // Triggers if you resend the confirm mail after register
        if (event.data.type === 'event' && event.data.action === 'showSnackbar') {
            this.events.emit('showSnackbar', event.data.label);
        }

        // Triggers if a parallel login-flow is started in another tab
        if (event.data.type === 'event' && event.data.action === 'loginExpired') {
            // Since this event can only be triggered from another tab, this window-instance isn't visible.
            // As soon as the user switches back to this tab and if the user didn't log-in in the meantime,
            // we will reopen the login-modal in order to start a new login-flow.
            // If the user is already logged-in and logs in again, the `RedirectIfAuthenticated`-middleware
            // will redirect the user to the requested location, so we don't need to intervene in this case.
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible' && !user.isLoggedIn(true)) {
                    this.reopen();
                }
            }, { once: true });
        }
    }

    toggle(src = false, hasParams = false) {
        if (!this.props.visible) {
            window.addEventListener('message', (e) => this.receiveMessageFromSSO(e), false);
        } else {
            this.events.emit('removeModalStyle');
            window.removeEventListener('message', (e) => this.receiveMessageFromSSO(e), false);
        }

        this.updateProps({ visible: !this.props.visible, src, hasParams }, true);
    }

    reopen() {
        if (this.props.visible) {
            this.updateProps({ visible: false }, true);
            this.updateProps({ visible: true }, true);
        }
    }

    gdprModalIsOpen() {
        return this.dom.contentInner
            ? this.dom.contentInner.classList.contains('m-loginModal__contentInner--gdpr') : false;
    }
}
